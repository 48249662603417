<template>
  <div class="box_sorting">
    <strong class="screen_out">문서 검색하기</strong>
    <ul class="list_sorting">
      <li class="item_full">
        <em class="tit_txt">정산서 등록</em>
        <div class="cont_sorting">
          <comm-opt
            class="w208"
            :option-desc="'정산서 등록여부 선택상자'"
            :option-name="settlementYnName"
            :option-list="settlementYnDataList"
            event-name="select"
            @select="selectSettlementYn"
          />
        </div>
      </li>
      <li class="item_sort">
        <em class="tit_txt">구매구분</em>
        <div class="cont_sorting">
          <comm-opt
            class="w208"
            :option-desc="'구매구분 선택상자'"
            :option-name="assetTypeName"
            :option-list="assetTypeDataList"
            event-name="select"
            @select="selectAssetType"
          />
        </div>
      </li>
      <li class="item_sort item_right">
        <em class="tit_txt">구매요청일</em>
        <div class="cont_sorting">
          <!-- 달력 입력 -->
          <div class="box_picker">
            <flat-pickr
              v-model="searchForm.startDate"
              class="txt_time"
              :config="flatpickrConfigDayCompleteFrom"
              placeholder="날짜선택"
              name="startDate"
              @on-change="listenToOnChangeDayCompleteFrom"
            />
            <span class="ico_account ico_calendar" />
            <span class="txt_bar">~</span>
            <flat-pickr
              v-model="searchForm.endDate"
              class="txt_time"
              :config="flatpickrConfigDayCompleteTo"
              placeholder="날짜선택"
              name="endDate"
              @on-change="listenToOnChangeDayCompleteTo"
            />
            <span class="ico_account ico_calendar" />
          </div>
        </div>
      </li>
      <li class="item_full">
        <em class="tit_txt">검색어</em>
        <div class="cont_sorting">
          <div class="group_search">
            <comm-opt
              class="w208"
              :class-list="['type_short']"
              :option-desc="'검색 선택상자'"
              :option-name="searchTypeName"
              :option-list="SEARCH_TYPE_SELECTBOX_LIST"
              event-name="select"
              @select="selectSearchType"
            />
            <div class="group_input">
              <Input :value.sync="searchForm.searchValue" :maxLength="100" @onEnterKey="keypress" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="wrap_btn">
      <button type="button" class="btn_large btn_primary" @click.prevent="onClickSearch">
        검색
      </button>
      <a href="javascript:void(0);" class="link_reset" @click.prevent="resetSearchForm">
        <span class="ico_account" />초기화
      </a>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";

export default {
  name: "StatementManagementListSearch",
  components: {
    CommOpt,
    Input,
  },
  mixins: [CommFlatpickrMixin, CommMomentMixin],
  data() {
    return {
      settlementYnDataList: [
        { code: "", name: "전체" },
        { code: "Y", name: "등록" },
        { code: "N", name: "미등록" },
      ],
      assetTypeDataList: [
        { code: "NONE", name: "전체" },
        { code: "INFRA", name: "자산구매" },
        { code: "GENERAL", name: "총무자산" },
        { code: "HANDLE", name: "일반구매" },
      ],
      SEARCH_TYPE_SELECTBOX_LIST: [
        { code: "request", name: "구매요청번호" },
        { code: "title", name: "구매요청명" },
        { code: "drafting", name: "구매요청자" },
        { code: "settlement", name: "정산서번호" },
      ],

      flatpickrConfigDayCompleteFrom: {},
      flatpickrConfigDayCompleteTo: {},
      searchForm: {
        settlementYn: "",
        assetType: "NONE",
        startDate: "",
        endDate: "",
        searchType: "request",
        searchValue: "",
      },
    };
  },
  computed: {
    settlementYnName() {
      if (!this.searchForm.settlementYn) {
        return this.settlementYnDataList.length > 0 ? this.settlementYnDataList[0].name : "";
      }
      return this.settlementYnDataList.find((item) => item.code === this.searchForm.settlementYn)
        .name;
    },
    assetTypeName() {
      if (!this.searchForm.assetType) {
        return this.assetTypeDataList.length > 0 ? this.assetTypeDataList[0].name : "";
      }
      return this.assetTypeDataList.find((item) => item.code === this.searchForm.assetType).name;
    },
    searchTypeName() {
      if (!this.searchForm.searchType) {
        return this.SEARCH_TYPE_SELECTBOX_LIST.length > 0
          ? this.SEARCH_TYPE_SELECTBOX_LIST[0].name
          : "";
      }
      return this.SEARCH_TYPE_SELECTBOX_LIST.find(
        (item) => item.code === this.searchForm.searchType,
      ).name;
    },
  },
  watch: {},
  mounted() {
    if (this.searchType) {
      this.searchForm.searchType = this.searchType;
    }
    this.$emit("init-search-form", this.searchForm);
  },
  created() {
    this.flatpickrConfigDayCompleteFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayCompleteTo = { ...this.flatpickrConfigDay };
    // 찾는 폼이 비어있지 않는 경우 init을 서칭 폼으로 매핑
    // if (localStorage.searchForm) {
    //   this.searchForm = JSON.parse(localStorage.searchForm);
    // },
  },
  methods: {
    keypress(key) {
      if (key.which === 13) {
        this.$emit("select-connector", this.searchForm);
      }
    },
    selectSettlementYn(selectedItem) {
      this.searchForm.settlementYn = selectedItem.code;
    },
    selectAssetType(selectedItem) {
      this.searchForm.assetType = selectedItem.code;
    },
    selectSearchType(selectedItem) {
      this.searchForm.searchType = selectedItem.code;
    },
    listenToOnChangeDayCompleteFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteTo.minDate = dStr;
      this.flatpickrConfigDayCompleteTo = {
        ...this.flatpickrConfigDayCompleteTo,
      };
    },
    listenToOnChangeDayCompleteTo(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteFrom.maxDate = dStr;
      this.flatpickrConfigDayCompleteFrom = {
        ...this.flatpickrConfigDayCompleteFrom,
      };
    },
    resetSearchForm() {
      this.searchForm = {
        settlementYn: "",
        assetType: "NONE",
        startDate: "",
        endDate: "",
        searchType: "title",
        searchValue: "",
      };
    },
    onClickSearch() {
      this.$emit("onClickSearch", this.searchForm);
    },
  },
};
</script>
